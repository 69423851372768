import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading2 from '../../ui/Heading2';
import ParagraphLarge from '../../ui/ParagraphLarge';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
    margin-bottom: 12px;
    padding: 80px 20px;
  }
`;

const Left = styled.div`
  background-color: ${COLORS.LIGHT_BLUE};
  width: 50%;
  padding: 253px 100px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 216px 40px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    background-color: ${COLORS.WHITE};
    width: 100%;
    padding: 0;
    padding-bottom: 60px;
  }
`;

const Header = styled(Heading2)`
  text-transform: capitalize;
  margin-bottom: 24px;
`;

const Right = styled.div`
  width: 50%;
  padding: 100px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 100px 40px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
    padding: 0;
    padding-top: 40px;
    border-top: 2px solid black;
  }
`;

const Table = styled.table`
  border-collapse: collapse;

  @media ${BREAKPOINTS.MOBILE} {
    margin-bottom: 0;
  }
`;

const Thead = styled.thead`
  font-size: 14px;
  line-height: 22px;

  th {
    padding-top: 0;
    padding-bottom: 24px;
    font-weight: 300 !important;
  }
`;

const Tbody = styled.tbody`
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
`;

const Caption = styled.caption`
  caption-side: bottom;
  font-size: 12px;
  font-style: italic;
  margin-top: 56px;
  text-align: left;
`;

interface Props {
  header: string;
  body: string;
  priceVaccines: string;
  priceBlood: string;
  priceFlea: string;
  priceFecal: string;
  priceNail: string;
  priceAnal: string;
}

const Billing = ({
  header,
  body,
  priceVaccines,
  priceBlood,
  priceFlea,
  priceFecal,
  priceNail,
  priceAnal,
}: Props) => {
  return (
    <Container>
      <Left>
        <Header>{header}</Header>
        <ParagraphLarge>{body}</ParagraphLarge>
        <ParagraphLarge
          css={css`
            display: none;
            @media ${BREAKPOINTS.MOBILE} {
              display: block;
              max-width: 520px;
              margin-bottom: 0;
            }
          `}
        >
          Here’s what some typical services cost at Small Door.
        </ParagraphLarge>
      </Left>
      <Right>
        <ParagraphLarge
          css={css`
            margin-bottom: 40px;
            @media ${BREAKPOINTS.MOBILE} {
              display: none;
            }
          `}
        >
          Here’s what some typical services cost at Small Door.
        </ParagraphLarge>
        <Table>
          <Thead>
            <tr>
              <th>Service</th>
              <th>Price Range</th>
            </tr>
          </Thead>
          <Tbody>
            <tr>
              <td>Annual Exam</td>
              <td>
                $0{' '}
                <span
                  style={{
                    textDecoration: 'line-through',
                    marginLeft: '.75em',
                  }}
                >
                  {/* note to easily find hard-coded prices for future update */}
                  $99 - $125
                </span>
              </td>
            </tr>
            <tr>
              <td>Vaccine</td>
              <td
                css={css`
                  @media screen and (max-width: 1300px) {
                    width: 145px;
                  }
                  @media ${BREAKPOINTS.TABLET} {
                    width: 140px;
                  }
                `}
              >
                {priceVaccines}
              </td>
            </tr>
            <tr>
              <td>Blood Test</td>
              <td>{priceBlood}</td>
            </tr>
            <tr>
              <td>Flea, Tick, & Heartworm Medication (6 mo)</td>
              <td>{priceFlea}</td>
            </tr>
            <tr>
              <td>Fecal Test</td>
              <td>{priceFecal}</td>
            </tr>
            <tr>
              <td>Nail Trim</td>
              <td>{priceNail}</td>
            </tr>
            <tr>
              <td>Anal Gland Expression</td>
              <td>{priceAnal}</td>
            </tr>
          </Tbody>
          <Caption>
            Service list not exhaustive. Prices subject to change.
          </Caption>
        </Table>
      </Right>
    </Container>
  );
};

export default Billing;
